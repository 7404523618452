import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { logout, parseJwt } from "../utils";
import Navbar from "./Navbar";

export default function SideNav() {
  const currentPath = useLocation().pathname;
  const [selected, setSelected] = useState("/costumer");
  const [collapsed, setCollapse] = useState(true);
  const user = parseJwt(sessionStorage.getItem("token"));
  const adminRoute = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: "door-open",
      childs: false,
    },
    {
      name: "Costumer",
      to: "/costumer",
      icon: "users",
      childs: false,
    },
    {
      name: "Keuangan",
      to: "/keuangan",
      icon: "cash-register",
      childs: [
        {
          name: "Dashboard",
          to: "/keuangan"
        },
        {
          name: "Pemasukan",
          to: "/income"
        },
        {
          name: "Pengeluaran",
          to: "/expense"
        }
      ],
    },
    {
      name: "Database",
      to: "/database",
      icon: "database",
      childs: [
        {
          name: "Paket&Daerah",
          to: "/database/paket-daerah",
        },
        {
          name: "Users",
          to: "/database/user",
        },
        {
          name: "RadiusGroups",
          to: "/database/radius_group",
        },
      ],
    },
    {
      name: "Settings",
      to: "/settings",
      icon: "gear",
      childs: false,
    },
  ];
  const cashierRoute = [
    {
      name: "Costumer",
      to: "/costumer",
      icon: "users",
      childs: false,
    },
    {
      name: "Laporan",
      to: "/income",
      icon: "clock-rotate-left",
      childs: false,
    },
  ];
  const subadminRoute = [
    {
      name: "Dashboard",
      to: "/dashboard",
      icon: "door-open",
      childs: false,
    },
    {
      name: "Costumer",
      to: "/costumer",
      icon: "users",
      childs: false,
    },
    {
      name: "Laporan",
      to: "/income",
      icon: "clock-rotate-left",
      childs: false,
    },
    {
      name: "Paket",
      to: "/database/paket",
      icon: "boxes-packing",
      childs: false,
    }
  ]
  const [items, setItems] = useState([]);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    setSelected(currentPath);
  }, [currentPath]);

  useEffect(() => {
    const navItems = (
      user && user.role === "admin" || user.role === "superadmin"
        ? adminRoute.map((item) =>
            item.name === "Database"
              ? {
                  ...item,
                  childs: isMobile
                    ? [
                        {
                          name: "Paket",
                          to: "/database/paket",
                        },
                        {
                          name: "Daerah",
                          to: "/database/daerah",
                        },
                        {
                          name: "Users",
                          to: "/database/user",
                        },
                      ]
                    : user.role === "superadmin" ? [
                        {
                          name: "Paket&Daerah",
                          to: "/database/paket-daerah",
                        },
                        {
                          name: "Users",
                          to: "/database/user",
                        },
                        {
                          name: "RadiusGroups",
                          to: "/database/radius_group",
                        },
                      ]: 
                      [
                        {
                          name: "Paket&Daerah",
                          to: "/database/paket-daerah",
                        },
                        {
                          name: "Users",
                          to: "/database/user",
                        }
                      ]
                }
              : item
          )
        : ( user.role === "subadmin" ? [...subadminRoute] : [...cashierRoute])
    );
    setItems(navItems)
  }, [isMobile]);

  return (
    <div className="fixed z-[2] h-10">
      {isMobile ? <Navbar collapse={[collapsed, setCollapse]} /> : undefined}
      <AnimatePresence>
        {!isMobile || !collapsed ? (
          <div>
            <motion.div
              className="h-[calc(100vh-2.5rem)] sm:h-screen w-screen sm:w-fit bg-[#284a836b]"
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              exit={{
                opacity: 0,
              }}
              transition={{ duration: 0.14 }}
              onClick={() => setCollapse(true)}
            >
              <motion.div
                className="bg-slate-800 w-[15rem] mt-[-1px] h-full"
                initial={{
                  x: "-100vw",
                }}
                animate={{
                  x: "0",
                }}
                exit={{
                  x: "-100vw",
                }}
                transition={{ duration: 0.14 }}
                onClick={(e) => e.stopPropagation()}
              >
                {items.map((item, i) => {
                  if (item.childs) {
                    const collapse = !(
                      selected === item.to ||
                      item.childs.find((x) => x.to === selected)
                    );
                    return (
                      <div key={i}>
                        <div
                          onClick={() =>
                            setSelected(collapse ? item.to : false)
                          }
                          className={
                            "flex justify-between items-center py-4 px-4 pr-6 pointer " +
                            (item.childs.find((x) => x.to === currentPath)
                              ? "bg-slate-700"
                              : "hover:bg-[#263247]")
                          }
                        >
                          <div key={i} className="flex items-center">
                            <FontAwesomeIcon
                              size="xl"
                              icon={item.icon}
                              color="white"
                              className="mr-3 w-7"
                            />
                            <div className="text font-semibold text-lg text-white">
                              {item.name}
                            </div>
                          </div>
                          <FontAwesomeIcon
                            style={{
                              transform: `rotate(${collapse ? 0 : 180}deg)`,
                            }}
                            className="transition-all"
                            size="xl"
                            color="white"
                            icon="caret-down"
                          />
                        </div>
                        <div
                          className={
                            "flex flex-row flex-wrap bg-[#1a2331] overflow-hidden transition-all"
                          }
                          style={{
                            height: collapse ? 0 : 32 * item.childs.length,
                          }}
                        >
                          {item.childs.map((child, j) => (
                            <Link
                              className={
                                "font-semibold text-white pl-8 flex items-center pointer w-full " +
                                (currentPath === child.to
                                  ? "bg-[#273448]"
                                  : "hover:bg-[#222d3d]")
                              }
                              key={j}
                              to={child.to}
                            >
                              <div className="py-1 border-l pl-4 border-slate-700">
                                {child.name}
                              </div>
                            </Link>
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <Link
                      key={i}
                      to={item.to}
                      className={
                        "py-4 px-4 flex items-center " +
                        (currentPath === item.to
                          ? "bg-slate-700"
                          : "hover:bg-[#263247]")
                      }
                    >
                      <FontAwesomeIcon
                        size="xl"
                        icon={item.icon}
                        color="white"
                        className="mr-3 w-7"
                      />
                      <div className="text font-semibold text-lg text-white">
                        {item.name}
                      </div>
                    </Link>
                  );
                })}
                <div
                  className="py-4 px-4 flex items-center hover:bg-[#263247]"
                  onClick={logout}
                >
                  <FontAwesomeIcon
                    size="xl"
                    icon="right-from-bracket"
                    color="white"
                    className="mr-3 w-7"
                  />
                  <div className="text font-semibold text-lg text-white">
                    Logout
                  </div>
                </div>
              </motion.div>
            </motion.div>
          </div>
        ) : undefined}
      </AnimatePresence>
    </div>
  );
}
