import axios from "axios";
import router from "../router";
import store from "../redux/store";
import Swal from "sweetalert2";
import { useCallback, useRef, useState } from "react";

export const baseURL = "http://103.217.224.219:14120/";

export const formatCur = (num) => {
  return "Rp " + num.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ".");
};

export const twoDigits = (number) => ("0" + number).slice(-2);

export const formatDate = (iso, date1) => {
  const date = date1 || new Date(iso);
  return (
    twoDigits(date.getDate()) +
    "-" +
    twoDigits(date.getMonth() + 1) +
    "-" +
    date.getFullYear()
  );
};

export const formatTimeDate = (iso) => {
  const date = new Date(iso);
  return (
    formatDate(iso) +
    " " +
    twoDigits(date.getHours()) +
    ":" +
    twoDigits(date.getMinutes())
  );
};

export const serialize = (obj) => {
  if (!obj) return "";
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return "?" + str.join("&");
};

export const errorHandler = (e) => {
  console.error(e);
  Swal.fire({
    icon: "error",
    title: "Error!",
    text: (e.response && e.response.data.message) || "An error has occurred",
  });
};

export const logout = () => {
  sessionStorage.removeItem("token");
  store.dispatch({ type: "SET_USER", payload: null });
  router.navigate("/");
};

export const expirationHandler = (e, setLoading = () => {}) => {
  if (e.response && e.response.data.message === "Unauthorized!") {
    logout();
  } else {
    setLoading(false);
    errorHandler(e);
  }
};

export let setToken = (token, role) => {
  let usedRole = role;
  if (role === "superadmin") {
    usedRole = "admin"
  }
  instance = axios.create({
    baseURL: baseURL + usedRole + "/",
    headers: {
      Authorization: token,
      "Access-Control-Allow-Origin": "*",
    },
  });
};

const waitLoop = (args) => {
  setTimeout(() => {
    if (instance.getUri() === baseURL + "guest/") {
      waitLoop(args);
    } else {
      args();
    }
  }, 100);
};

export const waitForToken = (args) => {
  if (instance.getUri() === baseURL + "guest/") {
    waitLoop(args);
  } else {
    args();
  }
};

export let instance = axios.create({
  baseURL: baseURL + "guest/",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

export const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 500 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    (event) => {
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => {
  return "touches" in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

export const preventLongPress = {
  onMouseDown: (e) => e.stopPropagation(),
  onTouchStart: (e) => e.stopPropagation(),
  onMouseUp: (e) => e.stopPropagation(),
  onMouseLeave: (e) => e.stopPropagation(),
  onTouchEnd: (e) => e.stopPropagation(),
};

export const parseJwt = (token) => {
  if (!token) return null;
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};
