import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { parseJwt } from "../utils";

export default function PrivateRoute({
  children,
  condition = !sessionStorage.getItem("token"),
  device = null,
  differentDevice = "/",
  allowedRole = ["cashier", "subadmin"],
  to = "/",
}) {
  const location = useLocation();
  const isMobile = useSelector((state) => state.isMobile);
  const token = sessionStorage.getItem("token");
  if (!token) return <Navigate to="/" state={{ from: location }} />;
  const role = parseJwt(token).role;
  if (condition) {
    return <Navigate to={to} state={{ from: location }} />;
  }
  if (role === "superadmin") {
    return children;
  }
  if (role !== "admin" && (typeof allowedRole === "string" ? allowedRole !== role : !allowedRole.includes(role))) {
    return <Navigate to={to} state={{ from: location }} />;
  }
  if (device !== null && isMobile !== device) {
    return <Navigate to={differentDevice} state={{ from: location }} />;
  }

  return children;
}
