import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../../components/Button";
import LoadingScreen from "../../components/LoadingScreen";
import Modal from "../../components/Modal";
import Select from "../../components/Select";
import TablePage from "../../components/TablePage";
import TextInput from "../../components/TextInput";
import {
  expirationHandler,
  instance,
  logout,
  serialize,
  waitForToken,
} from "../../utils";

const ChangePasswordForm = ({ state, onSubmit }) => {
  const [password, setPassword] = state;
  return (
    <form
      id="password"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <label className="text-sm font-medium ml-2">Password</label>
      <TextInput
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="New password"
      />
    </form>
  );
};

const AddUserForm = ({ passwordState, usernameState, roleState, daerahState, onSubmit }) => {
  const [password, setPassword] = passwordState;
  const [username, setUsername] = usernameState;
  const [role, setRole] = roleState;
  const [daerah, setDaerah] = daerahState;
  const [roles, setRoles] = useState([]);
  const [daerahs, setDaerahs] = useState([]);
  const [init, setInit] = useState(true);

  const textRole = roles.find(x => x.value === role);
  const showDaerah = textRole ? textRole.label === "cashier" || textRole.label === "subadmin" : false;

  const submitForm = (e) => {
    e.preventDefault();
    onSubmit();
  };

  useEffect(() => {
    if (!showDaerah) {
      setDaerah("");
    }
  }, [showDaerah]);

  useEffect(() => {
    instance.get("all-daerah").then(({ data }) => {
      setDaerahs(data.map((x) => ({ value: x.id, label: x.value })));
    });
    instance.get("all-role").then(({ data }) => {
      setRoles(data.map((x) => ({ value: x.id, label: x.value })));
    });
  }, []);

  return (
    <form className="flex flex-wrap justify-center" id="addUser" onSubmit={submitForm}>
      <div className="px-2 w-1/2">
        <label className="text-sm font-medium ml-2">Username</label>
        <TextInput
          className="w-full"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username"
          required
        />
      </div>
      <div className="px-2 w-1/2">
        <label className="text-sm font-medium ml-2">Password</label>
        <TextInput
          className="w-full"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="User Password"
          required
        />
      </div>
      <div className="px-2 w-1/2">
        <label className="text-sm font-medium ml-2">User Role</label>
        <Select
          options={roles}
          value={role}
          className="w-full"
          required
          outClass="w-full"
          onChange={(e) => setRole(e)}
        />
      </div>
      {showDaerah && (
        <div className="px-2 w-1/2">
          <label className="text-sm font-medium ml-2">Daerah</label>
          <Select
            options={daerahs}
            value={daerah}
            required
            className="w-full"
            outClass="w-full"
            onChange={(e) => setDaerah(e)}
          />
        </div>
      )}
    </form>
  );
};

const EditUserForm = ({ usernameState, roleState, daerahState, onSubmit }) => {
  const [username, setUsername] = usernameState;
  const [role, setRole] = roleState;
  const [roles, setRoles] = useState([]);
  const [daerah, setDaerah] = daerahState;
  const [daerahs, setDaerahs] = useState([]);
  const [init, setInit] = useState(true);

  const textRole = roles.find(x => x.value === role);
  const showDaerah = textRole ? textRole.label === "cashier" || textRole.label === "subadmin" : false;

  useEffect(() => {
    if (!showDaerah && !init) {
      setDaerah("");
    }
    if (init) setInit(false)
  }, [showDaerah]);

  useEffect(() => {
    instance.get("all-daerah").then(({ data }) => {
      setDaerahs(data.map((x) => ({ value: x.id, label: x.value })));
    });
    instance.get("all-role").then(({ data }) => {
      setRoles(data.map((x) => ({ value: x.id, label: x.value })));
    });
  }, []);

  return (
    <form
      id="editUser"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div>
        <div className="w-full mb-2">
          <label className="text-sm font-medium ml-2">Username</label>
          <TextInput
            className="w-full"
            placeholder="New Username"
            required
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="w-full mb-2">
          <label className="text-sm font-medium ml-2">User Role</label>
          <Select
            options={roles}
            value={role}
            className="w-full"
            outClass="w-full"
            required
            onChange={(e) => setRole(e)}
          />
        </div>
        { showDaerah && 
          <div className="w-full mb-2">
            <label className="text-sm font-medium ml-2">User Daerah</label>
            <Select
              options={daerahs}
              value={daerah}
              className="w-full"
              required
              outClass="w-full"
              onChange={(e) => setDaerah(e)}
            />
          </div>
        }
      </div>
    </form>
  );
};

export default function User({}) {
  const defaultOrder = { column: "id", asc: true, onDefault: true };
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [modalType, setModalType] = useState("");
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const emptyForm = {
    id: "",
    username: "",
    password: "",
    role: "",
    daerah: ""
  };
  const [userForm, changeUserForm] = useState({ ...emptyForm });
  const currentUser = useSelector((state) => state.user);
  const isMobile = useSelector((state) => state.isMobile);

  useEffect(() => {
    waitForToken(getUserStart);
  }, []);

  const getParams = (obj) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });

  const getUserStart = (search) => {
    setIsLoading(true);
    const param = {}
    if (search) {
      param.page = 0
    }
    instance
      .get("user" + getParams(param))
      .then(({ data }) => {
        setPage(1);
        setRows(data.value);
        setDataLength(data.length);
        setIsLoading(false);
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const deleteUser = (userData) => {
    Swal.fire({
      icon: "question",
      title: "Hapus user?",
      text: "Anda yakin ingin menghapus user " + userData.username + "?",
      showCancelButton: true,
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      setIsLoading(true);
      instance
        .delete("user/" + userData.id + getParams())
        .then(({ data }) => {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "User berhasil dihapus!",
          }).then(() => {
            if (userData.id === currentUser.id) return logout();
          });
          setPage(1);
          setRows(data.value);
          setDataLength(data.length);
        })
        .catch((e) => expirationHandler(e, setIsLoading));
    });
  };

  const editUser = () => {
    setIsLoading(true);
    const payload = {
      username: userForm.username,
      RoleId: userForm.role,
    };
    if (userForm.daerah) payload.DaerahId = userForm.daerah
    instance
      .put("user/" + userForm.id, payload)
      .then(({ data }) => {
        closeModal();
        setIsLoading(false);
        setRows(rows.map((x) => (x.id === data.id ? data : x)));
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Baris user telah berhasil dirubah",
        }).then(() => {
          if (data.id === currentUser.id) return logout();
        });
        changeUserForm({ ...emptyForm });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const addUser = () => {
    setIsLoading(true);
    const payload = {
      username: userForm.username,
      password: userForm.password,
      RoleId: userForm.role,
      RadiusGroupId: currentUser.grp
    };
    if (userForm.daerah) payload.DaerahId = userForm.daerah
    instance
      .post("user" + getParams(), payload)
      .then(({ data }) => {
        setIsLoading(false);
        closeModal();
        setPage(1);
        setRows(data.value);
        setDataLength(data.length);
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Telah menambahkan user baru",
        });
      })
      .catch((e) => expirationHandler(e, setIsLoading));
  };

  const changePassword = () => {
    setIsLoading(true);
    instance
      .put("user/password/" + userForm.id, { password: userForm.password })
      .then(() => {
        setIsLoading(false);
        closeModal();
        Swal.fire({
          icon: "success",
          title: "Berhasil",
          text: "Berhasil merubah password",
        });
      });
  };

  const setUserForm = (attr, newVal) => {
    const newForm = { ...userForm };
    newForm[attr] = newVal;
    changeUserForm(newForm);
  };

  const closeModal = () => {
    setModalType(false);
    changeUserForm({ ...emptyForm });
  };

  const showModal = () => {
    switch (modalType) {
      case "password":
        return (
          <Modal
            size="xs"
            title="Ganti password"
            body={
              <ChangePasswordForm
                state={[
                  userForm.password,
                  (newVal) => setUserForm("password", newVal),
                ]}
                onSubmit={changePassword}
              />
            }
            footer={
              <div className="flex justify-between">
                <Button onClick={closeModal}>Close</Button>
                <Button variant="dark" type="submit" form="password">
                  Submit
                </Button>
              </div>
            }
            close={closeModal}
          />
        );
      case "addUser":
        return (
          <Modal
            title="Tambah user"
            close={closeModal}
            body={
              <AddUserForm
                passwordState={[
                  userForm.password,
                  (e) => setUserForm("password", e),
                ]}
                usernameState={[
                  userForm.username,
                  (e) => setUserForm("username", e),
                ]}
                daerahState={[userForm.daerah, (e) => setUserForm("daerah", e)]}
                roleState={[userForm.role, (e) => setUserForm("role", e)]}
                onSubmit={addUser}
              />
            }
            footer={
              <div className="flex justify-between">
                <Button onClick={closeModal}>Close</Button>
                <Button variant="dark" type="submit" form="addUser">
                  Submit
                </Button>
              </div>
            }
          />
        );
      case "editUser":
        return (
          <Modal
            title="Edit user"
            size="xs"
            close={closeModal}
            body={
              <EditUserForm
                usernameState={[
                  userForm.username,
                  (e) => setUserForm("username", e),
                ]}
                roleState={[userForm.role, (e) => setUserForm("role", e)]}
                daerahState={[userForm.daerah, (e) => setUserForm("daerah", e)]}
                onSubmit={editUser}
              />
            }
            footer={
              <div className="flex justify-between">
                <Button onClick={closeModal}>Close</Button>
                <Button variant="dark" type="submit" form="editUser">
                  Submit
                </Button>
              </div>
            }
          />
        );
      case "mobileDetail":
        return (
          <Modal
            title="Action"
            close={closeModal}
            body={
              <div className="flex w-full flex-wrap">
                <div className="w-full px-1 mb-2">
                  <Button
                    className="w-full"
                    onClick={() => {
                      setModalType("password");
                      setUserForm("id", userForm.id);
                    }}
                  >
                    <FontAwesomeIcon icon="key" className="w-full" />
                    Ganti Password
                  </Button>
                </div>
                <div className="w-1/2 px-1">
                  <Button className="w-full" onClick={() => openEdit(userForm)}>
                    <FontAwesomeIcon icon="pen-to-square" className="w-full" />
                    Edit
                  </Button>
                </div>
                <div className="w-1/2 px-1">
                  <Button
                    className="w-full"
                    onClick={() => deleteUser(userForm)}
                  >
                    <FontAwesomeIcon icon="trash" className="w-full" />
                    Remove
                  </Button>
                </div>
              </div>
            }
          />
        );
    }
  };

  const checkRole = (role) => {
    switch (role) {
      case "admin":
        return "text-red-600 border-red-600 py-1 px-3 w-fit rounded-full text-sm bg-red-200 border font-medium";
      case "subadmin":
        return "text-violet-600 border-violet-600 py-1 px-3 w-fit rounded-full text-sm bg-violet-200 border font-medium";
      case "user":
        return "text-cyan-600 border-cyan-600 py-1 px-3 w-fit rounded-full text-sm bg-cyan-200 border font-medium";
      case "cashier":
        return "text-green-600 border-green-600 py-1 px-3 w-fit rounded-full text-sm bg-green-200 border font-medium";
      case "superadmin":
        return "text-indigo-600 border-indigo-600 py-1 px-3 w-fit rounded-full text-sm bg-indigo-200 border font-medium";
      default:
        return undefined;
    }
  };

  const openEdit = (data) => {
    changeUserForm({
      ...emptyForm,
      id: data.id,
      role: data.role.id,
      username: data.username,
      daerah: data.daerah
    });
    setModalType("editUser");
  };

  const leftHeader = (
    <Button
      variant="dark"
      className="w-full sm:w-fit"
      onClick={() => setModalType("addUser")}
    >
      <FontAwesomeIcon icon="plus-circle" className="mr-2" />
      Tambah User
    </Button>
  );
  const methods = {
    getParams,
    search: getUserStart,
  };
  const states = {
    searchInput: [searchInput, setSearchInput],
    page: [page, setPage],
    dataLength,
    setRows,
    sortBy: [sortBy, setSort],
    setIsLoading,
  };

  return (
    <div>
      <LoadingScreen show={isLoading} />
      {isMobile ? (
        <TablePage
          movePageRequest="user"
          leftHeader={leftHeader}
          methods={methods}
          state={states}
          columns={{
            header: [
              { label: "Username", column: "username" },
              { label: "Roles" },
            ],
            defaultOrder: defaultOrder,
            row: rows.map((data) => {
              const columns = [
                data.username,
                <div key={data.role.id} className={checkRole(data.role.name)}>
                  {data.role.name}
                </div>,
              ];
              return (
                <tr
                  key={data.id}
                  className="border-slate-400 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
                  onClick={() => {
                    changeUserForm(data);
                    setModalType("mobileDetail");
                  }}
                >
                  {columns.map((x, idx) => (
                    <td
                      key={idx}
                      className={
                        "items-center font-medium pb-2 text-center pt-2" +
                        (idx === 1 ? " flex justify-center" : "")
                      }
                    >
                      {x}
                    </td>
                  ))}
                </tr>
              );
            }),
          }}
        />
      ) : (
        <TablePage
          movePageRequest="user"
          leftHeader={leftHeader}
          methods={methods}
          state={states}
          columns={{
            header: [
              { label: "Id", column: "id" },
              { label: "Username", column: "username" },
              { label: "Action" },
              { label: "Roles" },
            ],
            row: rows.map((data) => {
              const columns = [
                data.id,
                data.username,
                <div>
                  <Button
                    className="mx-0.5"
                    onClick={() => deleteUser(data)}
                    title="Delete user"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Button>
                  <Button
                    onClick={() => {
                      setModalType("password");
                      setUserForm("id", data.id);
                    }}
                    title="Change Password"
                  >
                    <FontAwesomeIcon icon="key" />
                  </Button>
                  <Button
                    className="mx-0.5"
                    onClick={() => openEdit(data)}
                    title="Edit user"
                  >
                    <FontAwesomeIcon icon="pen-to-square" />
                  </Button>
                </div>,
                <div key={data.role.id} className={checkRole(data.role.name)}>
                  {data.role.name}
                </div>,
              ];
              return (
                <tr
                  key={data.id}
                  className="border-slate-400 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
                >
                  {columns.map((x, idx) => (
                    <td
                      key={idx}
                      className={
                        "items-center font-medium pb-2 text-center pt-2" +
                        (idx === 3
                          ? " flex justify-center"
                          : " w-[1%] whitespace-nowrap px-8")
                      }
                    >
                      {x}
                    </td>
                  ))}
                </tr>
              );
            }),
            defaultOrder: defaultOrder,
          }}
        />
      )}
      <AnimatePresence>{showModal()}</AnimatePresence>
    </div>
  );
}
