import { useEffect, useRef, useState } from "react";
import TablePage from "../../components/TablePage";
import { useSelector } from "react-redux";
import { instance, serialize, waitForToken } from "../../utils";
import Button from "../../components/Button";
import LoadingScreen from "../../components/LoadingScreen";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "../../components/Modal";
import TextInput from "../../components/TextInput";
import Swal from "sweetalert2";

const RadiusGroupForm = ({onSubmit, nameState, prefixState}) => {
  const [nama, setNama] = useState(nameState);
  const [prefix, setPrefix] = useState(prefixState);

  return (
    <form onSubmit={(e) => onSubmit(e, {name: nama, prefix})} className="flex flex-wrap justify-center" id="addGroup">
      <div className="px-2 w-full">
        <label className="text-sm font-medium ml-2">Nama</label>
        <TextInput
          className="w-full"
          value={nama}
          onChange={(e) => setNama(e.target.value)}
          placeholder="Group nama"
          required
        />
      </div>
      <div className="px-2 w-full">
        <label className="text-sm font-medium ml-2">Prefix</label>
        <TextInput
          className="w-full"
          value={prefix}
          onChange={(e) => setPrefix(e.target.value)}
          placeholder="Group Prefix"
          required
        />
      </div>
    </form>
  )
}

export default function RadiusGroup() {
  const defaultOrder = { column: "id", asc: true, onDefault: true };
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [dataLength, setDataLength] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [page, setPage] = useState(1);
  const [sortBy, setSort] = useState({ ...defaultOrder });
  const emptyForm = {
    id: "",
    name: "",
    prefix: ""
  }
  const [showForm, setShowForm] = useState(false);
  const [groupForm, changeGroupForm] = useState({ ...emptyForm });
  const states = {
    searchInput: [searchInput, setSearchInput],
    page: [page, setPage],
    dataLength,
    setRows,
    sortBy: [sortBy, setSort],
    setIsLoading,
  };

  useEffect(() => {
    waitForToken(getRadiusGroup)
  }, [])

  const getRadiusGroup = () => {
    setIsLoading(true);
    instance.get("radiusgroup" + getParams()).then((res) => {
      setRows(res.data.value);
      setDataLength(res.data.length);
      setIsLoading(false);
    })
  }
  const getParams = (obj) =>
    serialize({
      search: searchInput,
      page: page - 1,
      column: sortBy.column,
      direction: sortBy.asc,
      ...obj,
    });
  const methods = {
    getParams,
    search: getRadiusGroup,
  };

  const addGroup = (payload) => {
    setIsLoading(true);
    instance.post("radiusgroup" + getParams(), payload).then((res) => {
      setRows(res.data.value);
      setDataLength(res.data.length);
      setIsLoading(false);
      closeModal()
    })
  }

  const openEdit = (data) => {
    changeGroupForm(data);
    setShowForm(true)
  }

  const editGroup = (payload) => {
    setIsLoading(true);
    instance.put("radiusgroup/"+ groupForm.id + getParams(), payload).then((res) => {
      setRows(rows.map((x) => x.id === res.data.id ? res.data : x));
      setIsLoading(false);
      closeModal()
    })
  }

  const deleteGroup = (data) => {
    setIsLoading(true);
    Swal.fire({
      icon: "question",
      title: "Hapus group?",
      text: "Anda yakin ingin menghapus group  " + data.name + "?",
      showCancelButton: true,
      focusCancel: true,
    }).then(({ isConfirmed }) => {
      if (!isConfirmed) return;
      instance.delete("radiusgroup/"+ data.id + getParams()).then((res) => {
        setRows(res.data.value);
        setDataLength(res.data.length);
        setIsLoading(false);
      })
    })
  }

  const closeModal = () => {
    changeGroupForm({ ...emptyForm})
    setShowForm(false)
  }

  return (
    <div>
      <LoadingScreen show={isLoading} />
      {showForm && 
        <Modal
          size="xs"
          title={groupForm.id ? "Edit " + groupForm.name : "Tambah Group"}
          body={
            <RadiusGroupForm nameState={groupForm.name} prefixState={groupForm.prefix} onSubmit={(e, data) => {
              e.preventDefault();
              if (groupForm.id) return editGroup(data);
              addGroup(data);
            }} />
          }
          footer={
            <div className="flex justify-between">
              <Button onClick={closeModal}>Close</Button>
              <Button variant="dark" type="submit" form="addGroup">
                Submit
              </Button>
            </div>
          }
          close={closeModal}
        />
      }
      <TablePage
        movePageRequest="radiusGroup"
        leftHeader={<Button variant="dark" onClick={() => setShowForm(true)}><FontAwesomeIcon icon="plus-circle" className="mr-2" /> Tambah Group</Button>}
        methods={methods}
        state={states}
        columns={{
          header: [
            { label: "Nama", column: "nama" },
            { label: "Prefix", column: "prefix" },
            { label: "Action" },
          ],
          row: rows.map((data) => {
            const columns = [
              data.name,
              data.prefix,
              <div>
                <Button
                  className="mx-0.5"
                  title="Delete user"
                  onClick={() => deleteGroup(data)}
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
                <Button
                  className="mx-0.5"
                  title="Edit user"
                  onClick={() => openEdit(data)}
                >
                  <FontAwesomeIcon icon="pen-to-square" />
                </Button>
              </div>,
            ];
            return (
              <tr
                key={data.id}
                className="border-slate-400 transition hover:bg-[#a7b9cd] odd:bg-[#bdcada] last:border-b-0 border-b border-t"
              >
                {columns.map((x, idx) => (
                  <td
                    key={idx}
                    className={
                      "items-center font-medium pb-2 text-center pt-2"
                    }
                  >
                    {x}
                  </td>
                ))}
              </tr>
            );
          }),
          defaultOrder: defaultOrder,
        }}
      />
    </div>
  )
}