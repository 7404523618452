import { useEffect } from "react";
import TextInput from "../../components/TextInput";

export default function PaketFormBody({ onSubmit, modalForm, prefix }) {
  const [paketForm, setPaketForm] = modalForm;
  const changePaketForm = ({ target }, attr) => {
    const newForm = { ...paketForm };
    newForm[attr] = target.value;
    setPaketForm(newForm);
  };

  useEffect(() => {
    changePaketForm({target:  paketForm.nama.includes(prefix + "_") ? paketForm.nama.split("_").slice(1).join("_") : paketForm.nama}, "username")
  }, [])

  return (
    <form id="paketForm" onSubmit={onSubmit} className="flex flex-wrap">
      <div className="w-1/2 sm:px-3 px-1 py-1">
        <label className="text-sm font-medium">Nama</label>
        <TextInput
          onChange={(e) => changePaketForm(e, "nama")}
          placeholder="Nama"
          type="left-pad"
          leftText={prefix+"_"}
          className="w-full"
          value={paketForm.nama}
          required
        />
      </div>
      <div className="w-1/2 sm:px-3 px-1 py-1">
        <label className="text-sm font-medium">Harga</label>
        <TextInput
          onChange={(e) => changePaketForm(e, "harga")}
          type="number-comma"
          step={100000}
          className="w-full"
          placeholder="Harga"
          value={paketForm.harga}
          required
        />
      </div>
      <div className="w-1/2 sm:px-3 px-1 py-1">
        <label className="text-sm font-medium">Bandwidth</label>
        <TextInput
          onChange={(e) => changePaketForm(e, "bandwidth")}
          placeholder="Bandwidth"
          value={paketForm.bandwidth}
          className="w-full"
          required
        />
      </div>
    </form>
  );
}
